define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'moment',

  'navigation',
  
  "common/table/table-view",
  "common/visual/visual",

  'text-loader!history/list/layout.tpl',
  'text-loader!history/list/table.tpl',
  
], function($, _, Backbone, darsan, common, moment, navigate, TableView, visual,
             historyListTemplate, tableTemplate){

  var historyList = Object.create(visual).extend({
  
    parent: null,
    searchName: "",

    create: function(el,opt)
    {
      var me = this;
      visual.create.apply(me,arguments);

      me.renderFromTemplate(historyListTemplate, {});

      me.etypeLinks = me.options.labels.etype;
      actionTypes = me.options.actionTypes;

      // var el_table = $("<div />", {id: "table_place"});
      // var el_toolbar = $("<div />", { id: "toolbar_place", class: "btn-toolbar", role: "toolbar", style: "margin-bottom: 5px;margin-left: 0px;" });
      // el_toolbar.append('<div class="btn-group lead">История <span id="entity-type"></span> <a href="#" id="entity-href"></a></div>');
      // el_toolbar.append('<div class="btn-group pull-right" role="group" ><ul class="pagination" id="history-pagination" style="margin: 0px;"></ul></div>');

      // me.$el.append(el_toolbar);
      // me.$el.append(el_table);

      // var History = Backbone.ModelEx.extend({});

      // var CollHistory = Backbone.CollectionExPageable.extend({
      //   model: History,
      // });

      // me.collection = new CollHistory();

      var HistoryView = Backbone.TemplateView.extend({
        template: tableTemplate,
        el: me.$el.find("#table-history"),
        collection: me.collection,

        initialize: function(model)
        {
          this.compiled = _.template(this.template,null,{variable: "me"});
          this.listenTo(this.collection,"reset", this.render);
        },

        render: function() 
        {
          var markup = this.compiled({ history: this.collection.toJSON(), actionTypes: actionTypes, 
                                          labels: me.options.labels, moment: moment });
          this.$el.html(markup);

          return this;
        }
      });
          
      me.historyView = new HistoryView();
      me.historyView.render();

      // console.log(me.$el.find("#history-pagination"), me.$el.find("#history-pagination").parent().parent().parent().parent().parent() );
      me.pager = new Backbone.PagerView({
        el: me.$el.find("#history-pagination"),
        collection: me.collection,
      });
          
      me.pager.on("setPage",function(page) { navigate.changeState({list_page: page}) });
    },

    
    setState: function(state)
    {
      var me = this;
      // console.log("history.list.setState",state);

      var entity = state._rest.split("/")[2];
      var etype  = state._rest.split('/')[1];

      if (!(state.list_box || state.list_search || state.list_query)) me.searchName = "";
      if (me.searchName) me.$el.find("#search-name").empty().append("("+me.searchName+")");
      
      var st = common.filterState(state,"list");
      
      if (me.state!==null && _.isEqual(me.state, st) && me.state._rest==state._rest) return;
      me.state = st;
      
      var data = {};
      
      if (me.state.list_box)
      {
        data.box = me.state.list_box;
      }

      if (me.state.list_query)
      {
        delete data.box; 
        data.query = me.state.list_query;
      }
      
      if (me.state.list_search)
      {
        delete data.box; 
        delete data.query;
        data.search = me.state.list_search;
      }

      if (me.state.list_sort) 
      {
        data.sort = me.state.list_sort;
        
        var desc = false;
        var sort = data.sort;
        if (sort.substr(0,1)=="-")
        {
          sort = sort.substr(1);
          desc = true;
        }
        
        me.$el.find("th").removeClass("sorted-asc sorted-desc");
        me.$el.find("th#col-"+sort).addClass(desc ? "sorted-desc" : "sorted-asc");
      }
      
      data.page = me.state.list_page || 1;

      

      me.collection.url = me.collection.urlClean + "/entity" + state._rest + "";  
      

      // if ( !name_ru && !link){
      //   me.collection.url = "http://dev.mol.net.ua:2207" + "/history";            
      // }

      data.agreggate = 'admin';


      var link = me.options.labels.etype[etype] ?  me.options.labels.etype[etype].link : "";
      link = link.replace("{entity}", entity);
      var name_ru = me.options.labels.etype[etype] ? me.options.labels.etype[etype].text : "";

      me.$el.find("#entity-href").attr("href", link).html(entity);
      me.$el.find("#entity-type").html( name_ru );
      me.$el.find("#history-pagination").html('');


      me.collection.fetch({reset: true, data: data}).done(function(){
        
        
      });
    },


    
    setSearchName: function(str)
    {
      this.searchName = str;
    },
  });

  return historyList;
});