var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HistorySearch", {
    attrs: {
      name: "history",
      showAdmin: _vm.showAdmin,
      showClient: _vm.showClient,
      showEntity: _vm.showEntity,
      apiPath: _vm.apiPath,
      state: _vm.state.params,
      title: _vm.title,
      paged: true
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }