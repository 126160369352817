define([
  'common/visual/layout',
  'text-loader!history/flist/layout.tpl',

  "history/list/history-list",

  "common/search/search",

  "moment",

], function(Layout, Template, listMod, Search, moment)
{
  var searchMod = Object.create(Search).extend({
    metaAddress: ["","history","/meta/history/search"],
  });
  
  return Object.create(Layout).extend({
    template: Template,
    modParams: {},

    layout: {
      search: searchMod,
      list: listMod,
    },
  
    create: function(el,options)
    {
      this.modParams.collection = options.collection;
      this.modParams.labels = options.labels;
      this.modParams.actionTypes = options.actionTypes;
      return Layout.create.call(this, el, options);
    }, 

  });
  
});
